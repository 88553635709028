<script lang="ts">
    import DataTable, { Head, Body, Row, Cell, Pagination, Label, SortValue } from "@smui/data-table";
    import CircularProgress from "@smui/circular-progress";
    import Select, { Option } from "@smui/select";
    import IconButton from "@smui/icon-button";
    import Switch from "@smui/switch";
    import { mdiPencilBoxOutline, mdiDelete } from "@mdi/js";
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    import { unitList, productHierarchyList, temperatureList } from "../../../../stores/AppConfig";

    const dispatch = createEventDispatcher();

    // Product list data
    export let productList;
    export let pageToShow = true;
    export let currentPage = 0;

    // Loading icon
    export let loaded = false;
    export let tableSearchMessage = true;

    // Table Head
    const tableHead = [
        { columnId: "actions", label: "", numeric: false, extraClasses: "" },
        { columnId: "tpnEn", label: $_("tpn_en"), numeric: true, extraClasses: "sticky-col left-1x right-splitter" },
        { columnId: "description", label: $_("description"), numeric: false, extraClasses: "" },
        { columnId: "logistics", label: $_("logistics"), numeric: false, extraClasses: "" },
        { columnId: "temperature", label: $_("temperature"), numeric: false, extraClasses: "" },
        { columnId: "subGroupCode", label: $_("sub_group_code"), numeric: false, extraClasses: "" },
        { columnId: "unitsOfSale", label: $_("unitsOfSale"), numeric: false, extraClasses: "" },
        { columnId: "status", label: $_("status"), numeric: false, extraClasses: "" },
    ];

    // Pagging
    let rowsPerPage = 10;
    $: console.log({ currentPage });

    // Filter
    $: filteredData = productList;

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, filteredData.length);
    $: currentSlice = filteredData.slice(start, end);
    $: lastPage = Math.max(Math.ceil(filteredData.length / rowsPerPage) - 1, 0);
</script>

<DataTable stickyHeader table$aria-label="CE Products list" class="report" style="width: 100%; max-height: calc(100vh - 320px);">
    <Head>
        <Row>
            {#each tableHead as head (head.columnId)}
                <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header {head.extraClasses} text-center">
                    {$_(head.label)}
                </Cell>
            {/each}
        </Row>
    </Head>
    <Body>
        {#if !loaded}
            <Row class="datatable-content">
                <Cell colspan="10" class="text-center">
                    <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
                    {$_("loading_products_wait")}
                </Cell>
            </Row>
        {:else if currentSlice.length > 0}
            {#each currentSlice as row (row.id)}
                <Row>
                    <Cell class="pl-0 pr-0 sticky-col">
                        <!-- Edit button -->
                        <IconButton
                            title={$_("edit")}
                            size="button"
                            class="tescoblue-text"
                            on:click={() => {
                                dispatch("editProduct", { row });
                                pageToShow = false;
                            }}
                        >
                            <svg viewBox="0 0 24 24" class="tescoblue-text">
                                <path fill="currentColor" d={mdiPencilBoxOutline} />
                            </svg>
                        </IconButton>
                        <!-- Delete button -->
                        <IconButton title={$_("delete")} size="button" class="tescoblue-text" on:click={() => dispatch("confirmDeleteProduct", { id: row.id })}>
                            <svg viewBox="0 0 24 24" class="tescoblue-text">
                                <path fill="currentColor" d={mdiDelete} />
                            </svg>
                        </IconButton>
                    </Cell>
                    <Cell class="text-center sticky-col left-1x right-splitter">{row.id}</Cell>
                    <Cell class="text-center">{row.description}</Cell>
                    <Cell class="text-center">{row.cartonLogistics} / {row.palletLogistics}</Cell>
                    <Cell class="text-center">{$_([...$temperatureList].filter((x) => x.id == row.temperature).at(0)?.description)}</Cell>
                    <Cell class="text-center">{[...$productHierarchyList].filter((x) => x.subGrpCode == row.subGroupCode).at(0)?.subGrpDesc}</Cell>
                    <Cell class="text-center">{$_([...$unitList].filter((x) => x.code == row.units).at(0)?.description)}</Cell>
                    <Cell class="text-center">
                        <Switch bind:checked={row.status} on:click={() => dispatch("changeProductStatus", row)} />
                    </Cell>
                </Row>
            {/each}
        {:else if tableSearchMessage}
            <Row class="datatable-content">
                <Cell colspan="10" class="text-center" style="width: 100%">{$_("search_instructions")}</Cell>
            </Row>
        {:else}
            <Row class="datatable-content">
                <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
            </Row>
        {/if}
    </Body>

    <Pagination slot="paginate">
        <svelte:fragment slot="rowsPerPage">
            <Label>{$_("rows_per_page")}</Label>
            <Select variant="outlined" bind:value={rowsPerPage} noLabel>
                <Option value={rowsPerPage}>10</Option>
                <Option value={25}>25</Option>
                <Option value={100}>100</Option>
            </Select>
        </svelte:fragment>
        <svelte:fragment slot="total">
            {start + 1}-{end} of {filteredData.length}
        </svelte:fragment>

        <IconButton class="material-icons" action="first-page" title={$_("first_page")} on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
        <IconButton class="material-icons" action="prev-page" title={$_("prev_page")} on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
        <IconButton class="material-icons" action="next-page" title={$_("next_page")} on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
        <IconButton class="material-icons" action="last-page" title={$_("last_page")} on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
            >last_page</IconButton
        >
    </Pagination>
</DataTable>
